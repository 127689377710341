import { Alert, Button, Card, Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import logo from '../../asset/logo_0.png';
import { userLogin } from "../../data/accessMgnt";
import "./Login.css";

export default function Login({ setCookie }) {
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async(values) => {
    setLoading(true);

    userLogin(values)
      .then((response) => {
        setLoading(false);

        if (!response?.hasError){
          navigate("/");
        }else{
          setErrorMsg(response?.message);
        }
      })
      .catch((error) => {
        setErrorMsg(error.response.data?.message);
      });
  };
  
  const cardStyle = {
    width: "400px",
    borderRadius: "8px",
    marginRight: "24px",
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
  };

  const logoStyle = {
    width: "150px",
    borderRadius: "8px",
    margin: "24px"
  };

  return (
    <div align="center">
      <br />
      <br />
      <br/>
      <br />
      <img src={logo} alt="Logo" style={logoStyle}/>
      <Spin spinning={loading}>
        <Card title="Report Portal" style={cardStyle}>
          {errorMsg ? (
            <div style={{ marginBottom: 8 }}>
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          ) : (
            ""
          )}
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="E-Mail"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
