import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Modal, Popover } from "antd";
import Cookies from 'js-cookie';
import React from "react";
import { useNavigate } from "react-router";
import { userLogout } from "../../data/accessMgnt";
import "./index.css";

export default function UserAvaterSection() {
  const navigate = useNavigate();
  const text = <span>User Options</span>;

  const doLogout = () => {
    userLogout()
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        navigate("/");
      });
  };

  const alret = (type, title, msg) => {
    if (type === "fail") {
      Modal.error({
        title: title,
        content: msg,
      });
    } else if (type === "success") {
      Modal.success({
        title: title,
        content: msg,
      });
    } else {
      Modal.info({
        title: title,
        content: msg,
      });
    }
  };

  const content = (
    <div>
      <p>
        <Button type="link" icon={<LogoutOutlined />} onClick={doLogout}>
          Logout
        </Button>
      </p>
    </div>
  );

  return (
    <div>
      <span style={{ float: "right", marginRight: 15 }}>
        <b>{Cookies.get("x-user-name")}</b>
        <Popover
          placement="bottomRight"
          title={text}
          content={content}
          trigger="click"
        >
          <Button
            shape="circle"
            style={{ marginLeft: 10 }}
            icon={<UserOutlined />}
          />
        </Popover>
      </span>
    </div>
  );
}
