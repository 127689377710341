import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create();
const BASE_URL = process.env.REACT_APP_RP_BE_BASE_URL;

let isRefreshing = false;
let refreshTokenPromise = null;

axiosInstance.interceptors.request.use(
  async function (config) {
    const accessToken = Cookies.get("x-access-token");
    const refreshToken = Cookies.get("x-refresh-token");

    if (config.url.includes("/auth/login")) {
      //No need to add any token
    } else if (config.url.includes("/auth/logout")) {
      config.headers.Authorization = `Bearer ${refreshToken}`;

      Cookies.remove("x-access-token");
      Cookies.remove("x-refresh-token");
      Cookies.remove("x-user-role");
      Cookies.remove("x-user-name");
    } else if (config.url.includes("/auth/refresh-token")) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  function (response) {

    if (response.config.url.includes("/auth/login")) {
      Cookies.set("x-access-token", response.data?.access_token);
      Cookies.set("x-refresh-token", response.data?.refresh_token);
      Cookies.set("x-user-role", response.data?.role);
      Cookies.set("x-user-name", response.data?.user_name);
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      !error.config.url.includes("/auth/login") &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const response = await axiosInstance.get(BASE_URL+"/auth/refresh-token");

          Cookies.set("x-access-token", response.data?.access_token);
          Cookies.set("x-refresh-token", response.data?.refresh_token);

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          Cookies.remove('x-access-token');
          Cookies.remove('x-refresh-token');
          Cookies.remove('x-user-role');
          Cookies.remove('x-user-name');

          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      } else {
        return refreshTokenPromise.then(() => {
          return axiosInstance(originalRequest);
        });
      }
    }

    return Promise.reject({hasError: true, "message": error.response.data?.message});
  }
);

export default axiosInstance;