import { Select, Table } from "antd";
import React from "react";
const { Option } = Select;

class DataTable extends React.Component {
  
  constructor(props) {
    super(props);
    this.props = props;
  }
  
  state = {
    tableColumns:[],
  };


  render() {
    const children = [];

    const columns = this.props.columns ? this.props.columns : [];
    const data = this.props.dataSource ? this.props.dataSource : [];
    const noOfData = this.props.noOfData ? this.props.noOfData : 0;

    /*Setting search criteria on column*/
    columns && columns.forEach((element) => {
      if (element) {
        const indexName = element.dataIndex;
        children.push(<Option key={indexName}>{element.title}</Option>);
      }

    });

    return (
      <div>
        {
        <Table
          columns={columns.map((column) => {
            if (column.dataIndex === 'action') {
              return column;
            }
            return {
              ...column,
              render: (text) => {
                if (!text) {
                  return null; 
                }
                return (
                  <span>
                    {text.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                );
              },
            };
          })}
          dataSource={data}
          rowKey="id"
          scroll={{ x: 'calc(100%)' }}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            total: noOfData,
            onChange:(offset, limit)=>{
              this.props.updateTableData(offset, limit);
            },
            pageSizeOptions: ["10","50", "100","200", "500","1000"],
          }}
        />
        }
      </div>
    );
  }
}

export default DataTable;
