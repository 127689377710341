import React from "react";
import AppLayout from "../../components/layout/AppLayout";
import DataViewer from "./ReportViewer";


export default function DataViewerPage({cookies}){
    return (
        <div>
            <AppLayout pageData={<DataViewer cookies={cookies}/>} selectedMenu={'reports'}/>
        </div>
    );
}