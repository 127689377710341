import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import { loadReportsConfig } from "../../data/reportMgnt";
import AddUpdateserviceReport from "./AddUpdateReport";


export default function Services() {
    const [columns, setColumns] = useState([]);
    const [noOfData, setNoOfData] = useState(0);
    
    const [paginationLimit, setPaginationLimit] = useState(50);
    const [paginationOffset, setPaginationOffset] = useState(1);

    const [serviceReports, setServiceReports] = useState([]);
    const [serviceReport, setServiceReport] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isUpdate, setIsUpdate] = useState(false);
    const [formShow, setFormShow] = useState(false);

    const fetchServiceReports = async () => {
        setLoading(true);

        loadReportsConfig()
        .then((response) => {
          setLoading(false);
  
          if (!response?.hasError){
            setServiceReports(response.data?.data);
          }else{
            //setErrorMsg(response?.message);
          }
        })
        .catch((error) => {
            setLoading(false);
          //setErrorMsg(error.response.data?.message);
        });
    };

    
    /* On changes the fatch data */
    useEffect(() => {
        let tableColumns = [];

        if (serviceReports.length > 0) {
            Object.keys(serviceReports[0]).map((key) => {
                var column = {
                    title: key,
                    dataIndex: key,
                    key: key,
                };
                tableColumns.push(column);
            });

            tableColumns.push({
                isSearchAble: false,
                isSortable: false,
                title: "Action",
                dataIndex: "action",
                key: "id",
                width: "105px",
                render: (text, record) => (
                  <Space size="middle">
                    <Popconfirm
                      title="Sure to edit?"
                      onConfirm={() => editAction(record)}
                    >
                      <Button
                        shape="circle"
                        style={{ color: "blue" }}
                        icon={<EditOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                ),
            });

            setColumns(tableColumns);
        }
    }, [serviceReports]);


    /* Hit initially */
    useEffect(() => {
        fetchServiceReports();
    }, []);

    const editAction = (record) => {
        setFormShow(true);
        setIsUpdate(true);
        setServiceReport(record);
    };

    const setServiceReportForEdit = (value) => {
        setServiceReport(value);
    };

    const handleFormPanel = () => {
        setIsUpdate(false);
        formShow ? setFormShow(false) : setFormShow(true);
    };

    return (
        <div>
            {formShow ? (
                <AddUpdateserviceReport
                    serviceReport={serviceReport}
                    setFormShow={setFormShow}
                    isUpdate={isUpdate}
                    serviceReports={serviceReports}
                    setserviceReports={setServiceReports}
                />
            ) : (
                ""
            )}

            {!formShow ? (
            <div
                style={{ display: "flex", justifyContent: "flex-end", margin: 8 }}
            >
                <Button
                type="primary"
                icon={<PlusOutlined />}
                size={"medium"}
                onClick={handleFormPanel}
                >
                Add Report
                </Button>
            </div>
            ) : (
            ""
            )}

            <Spin spinning={loading}>
                <DataTable
                    columns={columns}
                    dataSource={serviceReports}
                    noOfData={noOfData}
                    updateTableData={(offset, limit) => {
                        setPaginationLimit(limit);
                        setPaginationOffset(offset);
                    }}
                />
            </Spin>
        </div>
    );
}
