import { OrderedListOutlined, SettingOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";
import logo from '../../asset/logo_white_text.png';
import UserAvaterSection from "./UserAvaterSection";
import "./index.css";

const { Header, Content, Footer } = Layout;

const logoStyle = {
  width: "100px",
  borderRadius: "8px",
  margin: "5px"
};

const AppLayout = ({ pageData, selectedMenu }) => {
  return (
    <Layout>
      <Header
        style={{ display: "flex", alignItems: "center", padding: "0 10px" }}
      >
        <table border="0" width="100%">
          <tbody>
            <tr width="100%">
              <td>
                <div
                  className="logo"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "yellow",
                    fontStyle: "italic",
                  }}
                >
                  <img src={logo} alt="Logo" style={logoStyle}/> 
                </div>
                <Menu
                  style={{ flex: 1, minWidth: 0 }}
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={["reports"]}
                  selectedKeys={[selectedMenu]}
                >
                  <Menu.Item key="reports" icon={<OrderedListOutlined />}>
                    <Link to="/reports">
                      <span className="nav-text"> Reports</span>
                    </Link>
                  </Menu.Item>

                  {Cookies.get("x-user-role") === "ADMIN" ? (
                    <Menu.Item key="report_settings" icon={<SettingOutlined />}>
                      <Link to="/report_settings">
                        <span className="nav-text"> Report Settings</span>
                      </Link>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
              </td>
              <td>
                <UserAvaterSection />
              </td>
            </tr>
          </tbody>
        </table>
      </Header>
      <Content>
        <div className="site-layout-content">{pageData}</div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Letshego©2024, Integration
      </Footer>
    </Layout>
  );
};

export default AppLayout;
