import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateOutlet from "../components/PrivateOutletRoute";
import DataViewerPage from "../pages/home/ReportViewerPage";
import Login from "../pages/login/Login";
import ServicesPage from "../pages/report/ReportSettingsPage";
import './App.css';

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/reports" />} />
          <Route path="/login" element={<Login />} />

          <Route path="/*" element={<PrivateOutlet />}>
            <Route path="reports" element={<DataViewerPage />} />
            <Route path="report_settings" element={<ServicesPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
