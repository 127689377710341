import axiosInstance from "./apiRequestHandler";
const BASE_URL = process.env.REACT_APP_RP_BE_BASE_URL;

export const getServiceReports = async (values) => {
  try {
    const response = await axiosInstance.get(
      BASE_URL + "/services/get-reports"
    );
    return response;
  } catch (error) {
    return error;
  }
};

/*Load report data based on pagination and offset*/
export const loadReportData = async (
  serviceName,
  reportName,
  searchConditions,
  paginationLimit,
  paginationOffset
) => {
  try {
    const response = await axiosInstance.post(
      BASE_URL +
        "/services/report?limit=" +
        paginationLimit +
        "&offset=" +
        paginationOffset,
      {
        service_name: serviceName,
        report: reportName,
        params: searchConditions,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const loadFullReportData = async (
  serviceName,
  reportName,
  searchConditions
) => {
  try {
    const response = await axiosInstance.post(
      BASE_URL + "/download/report/csv",
      {
        service_name: serviceName,
        report: reportName,
        params: searchConditions,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const loadReportsConfig = async () => {
  try {
    const response = await axiosInstance.get(BASE_URL + "/services/report-config-list");
    return response;
  } catch (error) {
    return error;
  }
};


export const insertReportConfig = async (reportConfig) => {
  try {
    const response = await axiosInstance.post(BASE_URL + "/services/create-report", reportConfig);
    return response;
  } catch (error) {
    return error;
  }
};


export const updateReportConfig = async (reportConfig) => {
  try {
    const response = await axiosInstance.put(BASE_URL + "/services/update-report/"+ reportConfig?.id, reportConfig);
    return response;
  } catch (error) {
    return error;
  }
};
