import React from "react";
import AppLayout from "../../components/layout/AppLayout";
import Services from "./ReportList";


export default function ServicesPage(){
    return (
        <div>
            <AppLayout pageData={<Services/>} selectedMenu={'report_settings'}/>
        </div>
    );
}