import axiosInstance from "./apiRequestHandler";
const BASE_URL = process.env.REACT_APP_RP_BE_BASE_URL;

export const userLogin = async (values) => {
  try {
    const response = await axiosInstance.post(BASE_URL + "/auth/login", values);
    return response;
  } catch (error) {
    return error;
  }
};

export const userLogout = async (values) => {
  try {
    const response = await axiosInstance.get(BASE_URL + "/auth/logout");
    return response;
  } catch (error) {
    return error;
  }
};