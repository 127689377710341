import { CloudDownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Form, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import { SearchComponent } from "../../components/SearchComponent";
import { getServiceReports, loadFullReportData, loadReportData } from "../../data/reportMgnt";

const { Option } = Select;

export default function DataViewer() {
  const [columns, setColumns] = useState([]);
  const [noOfData, setNoOfData] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [paginationOffset, setPaginationOffset] = useState(1);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceReports, setServiceReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportParams, setSelectedReportParams] = useState(null);
  const [searchComponent, setSearchComponent] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchServiceReports = async () => {
    setLoading(true);

    getServiceReports()
      .then((response) => {
        setLoading(false);
        setServices(response.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getReportData = async (
    serviceName,
    queryName,
    searchCondition,
    limit,
    offset
  ) => {
    setLoading(true);

    loadReportData(
      serviceName,
      queryName,
      searchCondition,
      paginationLimit,
      paginationOffset
    )
      .then((response) => {
        setLoading(false);
        setNoOfData(response.data?.data?.length);
        setReportData(response.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const downloadReport = async (serviceName, queryName) => {
    setLoading(true);

    loadFullReportData(serviceName, queryName, searchCriteria)
      .then((response) => {
        setLoading(false);

        let blob = new Blob([response.data], { type: "text/csv" }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = serviceName + "_" + queryName;

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  /* On changes the fatch data */
  useEffect(() => {
    let tableColumns = [];

    if (reportData?.length > 0) {
      Object.keys(reportData[0]).map((key) => {
        var column = {
          title: key,
          dataIndex: key,
          key: key,
        };
        tableColumns.push(column);
      });

      setColumns(tableColumns);
    }
  }, [reportData]);

  /* Hit when Service change and load query list*/
  useEffect(() => {
    const serviceData = services.find(
      (item) => item.service_name === selectedService
    );
    const reports = serviceData ? serviceData.reports : [];

    setServiceReports(reports);
  }, [selectedService]);

  /* Hit when Query change and load query list*/
  useEffect(() => {
    let components = [];
    if (
      selectedReportParams != null &&
      selectedReportParams !== "null" &&
      selectedReportParams !== ""
    ) {
      var params = selectedReportParams.split(",");
      params.map((param) => {
        var component = {
          componentType: "input",
          label: param,
          required: false,
          name: param,
        };
        components.push(component);
      });
    }

    setSearchComponent(components);
  }, [selectedReportParams]);

  /* Hit initially */
  useEffect(() => {
    fetchServiceReports();
  }, []);

  /* Hit When pagiantion change */
  useEffect(() => {
    if (selectedService) {
      getReportData(
        selectedService,
        selectedReport,
        searchCriteria,
        paginationLimit,
        paginationOffset
      );
    }
  }, [paginationLimit, paginationOffset]);

  const onFinish = (values) => {
    const formattedData = Object.entries(values).map(([name, value]) => ({
      name,
      value,
    }));

    setSearchCriteria(formattedData);
    getReportData(
      selectedService,
      selectedReport,
      formattedData,
      paginationLimit,
      paginationOffset
    );
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Card>
          <div className="card-content">
            <div key="search-left-section" className="left-section">
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Service"
                onChange={(value, event) => {
                  setSelectedService(value);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {services.map((service) => (
                  <Option
                    key={service.service_name}
                    value={service.service_name}
                  >
                    {service.service_name}
                  </Option>
                ))}
              </Select>
              <Select
                showSearch
                style={{ width: 200 }}
                onChange={(value, event) => {
                  var queryParam = value.split("#");

                  setSelectedReport(queryParam[0]);

                  if (queryParam.length > 1)
                  setSelectedReportParams(queryParam[1]);
                  else setSelectedReportParams(null);
                }}
                placeholder="Select Report"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {serviceReports.map((query) => (
                  <Option
                    key={query.service_name}
                    value={query.report + "#" + query.params}
                  >
                    {query.report}
                  </Option>
                ))}
              </Select>
            </div>

            <div key="search-right-section" className="right-section">
              
                <Form onFinish={onFinish}>
                  <Row>
                  {searchComponent.length > 0 ? (
                    searchComponent.map((component) => (
                      <SearchComponent {...component} />
                    ))) : (
                      ""
                    )}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                      >
                        Find Data
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              
            </div>
          </div>
        </Card>
      </Spin>
      <br />
      {noOfData == null || noOfData < 1 ? (
        ""
      ) : (
        <Button
          type="primary"
          style={{ background: "green", borderColor: "green" }}
          icon={<CloudDownloadOutlined />}
          size={"small"}
          onClick={() => {
            if (selectedService) {
              downloadReport(selectedService, selectedReport);
            }
          }}
        >
          Download CSV Report
        </Button>
      )}
      <DataTable
        columns={columns}
        dataSource={reportData}
        noOfData={noOfData}
        updateTableData={(offset, limit) => {
          setPaginationLimit(limit);
          setPaginationOffset(offset);
        }}
      />
    </div>
  );
}
