import { Alert, Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { insertReportConfig, updateReportConfig } from "../../data/reportMgnt";
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 16,
  },
};

const addReport = async (
  values,
  setAlertMsgType,
  setAlertMsg,
  setServiceReports,
  form,
  setLoading
) => {
  setLoading(true);

  insertReportConfig(values)
    .then((response) => {
      setLoading(false);

      if (!response?.hasError) {
        setAlertMsgType("success");
        setAlertMsg("Service Query Added Successfully.");

        setServiceReports((pre) => {
          return [...pre, response?.data?.data[0]];
        });

        form.resetFields();
      } else {
        setAlertMsgType("error");
        setAlertMsg(response?.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      setAlertMsg(error.response.data?.error);
    });
};

const updateReport = async (
  values,
  setAlertMsgType,
  setAlertMsg,
  setServiceReports,
  setLoading
) => {
  setLoading(true);

  updateReportConfig(values)
    .then((response) => {
      setLoading(false);

      if (!response?.hasError) {
        setAlertMsgType("success");
        setAlertMsg("Service Query Updated successfully");

        setServiceReports((pre) => {
          return pre.map((query) => {
            if (query.id === response?.data?.data[0].id) {
              return response?.data?.result?.data[0];
            } else {
              return query;
            }
          });
        });
      } else {
        setAlertMsgType("error");
        setAlertMsg(response?.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      setAlertMsg(error.response.data?.error);
    });
};

export default function AddUpdateServiceReport({
  serviceReport,
  isUpdate,
  setFormShow,
  serviceReports,
  setServiceReports,
}) {
  const [alertMsgType, setAlertMsgType] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    isUpdate
      ? updateReport(
          values,
          setAlertMsgType,
          setAlertMsg,
          setServiceReports,
          setLoading
        )
      : addReport(
          values,
          setAlertMsgType,
          setAlertMsg,
          setServiceReports,
          form,
          setLoading
        );
  };

  useEffect(() => {
    if(isUpdate)
        form.setFieldsValue(serviceReport);
  }, [serviceReport]);

  var operation = isUpdate ? "Update Report" : "Add Report";

  let mandatoryCondition = [{ required: true }];

  const closeForm = () => {
    setFormShow(false);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Card title={operation}>
          {alertMsg ? (
            <div style={{ marginBottom: 8 }}>
              <Alert message={alertMsg} type={alertMsgType} showIcon closable />
            </div>
          ) : (
            ""
          )}
          <Form {...layout} name="control-ref" onFinish={onFinish} form={form}>
            <Row>
              <Col span={12}>
                <Form.Item name="id" label="Id" hidden={true}>
                  <Input />
                </Form.Item>

                <Form.Item
                  name="institution"
                  label="Institution"
                  rules={mandatoryCondition}
                >
                  <Select placeholder="Select institution" allowClear>
                    <Option value="ALL">ALL</Option>
                    <Option value="LHL">LHL</Option>
                    <Option value="MASCOM">MASCOM</Option>
                    <Option value="BTC">BTC</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="country"
                  label="Country"
                  rules={mandatoryCondition}
                >
                  <Select placeholder="Select country" allowClear>
                    <Option value="ALL">ALL</Option>
                    <Option value="Botswana">Botswana</Option>
                    <Option value="Eswatini">Eswatini</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="service_name"
                  label="Service Name"
                  rules={mandatoryCondition}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="report"
                  label="report"
                  rules={mandatoryCondition}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="db_name"
                  label="Data Source"
                  rules={mandatoryCondition}
                >
                  <Select placeholder="Select Data source" allowClear>
                    <Option value="default_db">WSO2-DB</Option>
                    <Option value="momo_loan_db">MOMO-LOAN-DB</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="query"
                  label="Query"
                  rules={mandatoryCondition}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={mandatoryCondition}
                >
                  <Select placeholder="Select user status" allowClear>
                    <Option value="ACTIVE">ACTIVE</Option>
                    <Option value="INACTIVE">INACTIVE</Option>
                  </Select>
                </Form.Item>

                <Form.Item name="params" label="Params">
                  <Input />
                </Form.Item>

                <Form.Item name="remarks" label="Remarks">
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                  &nbsp;
                  <Button htmlType="button" onClick={closeForm}>
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
