import Cookies from 'js-cookie';
import { useLocation } from 'react-router';
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateOutletRoute() {

    const location = useLocation();
    let auth=false;
    const c = Cookies.get("x-access-token");
    
    if(c!=null) auth= true;
    return auth? <Outlet/>: <Navigate to="/login" replace state={{from:location}}/>;
}
