import { DatePicker, Form, Input } from "antd";
const { RangePicker } = DatePicker;

const componentMapping = {
  input: Input,
  password: Input.Password,
  dateTime: DatePicker,
  dateTimeRange: RangePicker,
};
const dateTimeFormat = 'YYYY-MMM-DD HH:mm:ss';

export const SearchComponent = ({ componentType, label, required, name }) =>{
  const Component = componentMapping[componentType];
  return (
    <Form.Item
      name={name}
      rules={[{ required, message: "Field is required!" }]}
    >
      {name.includes('DateTime')? <DatePicker showTime format={dateTimeFormat} placeholder={name}/>:<Component  placeholder={label}/>}
    </Form.Item>
  );
}